<template>
  <div class="" style="padding-bottom: 10%">
    <div class="zxw-nav" style="padding-left: 0px;padding-bottom: 10px">
      <div class="wrap pr">
        <div class="uf uf-pc">
          <li class="" @click="$router.push({ name: 'serve' })">首页</li>
          <li class="pr" @click="$router.push({ name: 'index' })">
            <i class="hot iconfont icon-hot"></i
            ><span class="fwb">自助式服务</span>
          </li>
          <li class="pr" @click="$router.push({ name: 'tech-analysis' })">
            <i class="hot iconfont icon-hot"></i
            ><span class="fwb">推荐式服务</span>
          </li>
          <li class="pr on" @click="$router.push({ name: 'quality-doctor' })">
            <i class="hot iconfont icon-hot"></i
            ><span class="fwb">一站式服务</span>
          </li>
          <li style="list-style-type: none" @click="toStandardbzfw">
            标准服务
          </li>
          <li style="list-style-type: none" @click="toStandardqyxycx">
            企业信用查询
          </li>
          <li style="list-style-type: none" @click="toStandardzcfw">
            政策服务
          </li>
          <li style="list-style-type: none" @click="toStandardzwqyml">
            小微企业名录
          </li>
          <li style="list-style-type: none" @click="toStandardrzrkfw">
            认证认可服务
          </li>
          <li style="list-style-type: none" @click="toStandardsbjs">
            商标工作台
          </li>
          <li style="list-style-type: none" @click="toSCRM">SCRM</li>
        </div>
      </div>
    </div>
    <div class="banner2">
      <div class="wrap uf uf-ac uf-pc">
        <div class="banner-txt">
          <div class="tit uf uf-ac">
            <!--<i-->
            <!--class="iconfont icon-zhiliang"-->
            <!--style="font-size: 4vw;width: 4vw"-->
            <!--&gt;</i>-->
            <span class="fwb">一站式服务</span>
          </div>

          <div class="txt">
            <div class="mt-4 uf">
              <span
                >针对服务需求不明确的平台企业，研究政策方针，汇聚行业数据，分析市场动态，挖掘企业需求，为平台企业定制个性化一站式服务方案</span
              >
            </div>
          </div>
        </div>
        <div class="ml-5" style="width: 22%">
          <img
            src="../../../public/img/yizhishi.png"
            style="width: 100%"
            alt=""
          />
        </div>
      </div>
    </div>
    <div class="container">
      <div class="line-nav uf uf-ac uf-pc">
        <div
          class="nav-item"
          :class="{ active: activeIndex === index }"
          v-for="(item, index) in lineNav"
          @click="selectNav(index)"
          :key="index"
        >
          <i class="iconfont" :class="item.icon"></i>
          <span>{{ item.label }}</span>
        </div>
      </div>
      <div class="nav-content" v-if="activeIndex === 0">
        <div class="form-box">
          <div class="mb-2 f14 op-08">留下您的信息，我们将尽快与您联系：</div>
          <el-form :model="user" ref="user" :rules="dataRule">
            <el-row :gutter="10">
              <el-col>
                <el-form-item prop="name">
                  <el-input v-model="user.name" placeholder="姓名"></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="10">
              <el-col>
                <el-form-item prop="enterpriseName">
                  <el-input
                    v-model="user.enterpriseName"
                    placeholder="公司名称"
                  ></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="10">
              <el-col>
                <el-form-item prop="mobile">
                  <el-input
                    v-model="user.mobile"
                    placeholder="手机号"
                  ></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="10">
              <el-col>
                <el-form-item>
                  <div v-if="isMobile">
                    <slider-mobile ref="slider"></slider-mobile>
                  </div>
                  <div v-else>
                    <slider
                      :key="timer"
                      ref="slider"
                      :mobile="this.user.mobile"
                      :type="type"
                    ></slider>
                  </div>
                </el-form-item>
              </el-col>
            </el-row>
            <el-form-item prop="smsCode">
              <el-row :gutter="24">
                <el-col :span="12">
                  <el-input
                    prefix-icon="el-icon-key"
                    v-model="user.smsCode"
                    placeholder="短信验证码"
                  ></el-input>
                </el-col>
                <el-col :span="12">
                  <div class="qrcode">
                    <template v-if="smsSuc">
                      <el-button type="danger" style="margin-left: 30%"
                        ><span class="f8">{{ smsTime }}</span></el-button
                      >
                    </template>
                    <template v-else>
                      <el-button
                        style="margin-left: 30%"
                        type="primary"
                        slot="reference"
                        plain
                        @click="getMsgCode"
                        >获取验证码</el-button
                      >
                    </template>
                  </div>
                </el-col>
              </el-row>
            </el-form-item>
            <el-form-item class="tac">
              <el-button
                class="login-btn wi50"
                type="primary"
                @click="doSub"
                :loading="loading"
                >提交</el-button
              >
            </el-form-item>
          </el-form>
        </div>
      </div>
      <div class="nav-content" v-if="activeIndex === 1">
        <div>
          <el-dialog
            :visible.sync="wechatVisible"
            width="70%"
            @close="cancel"
            :close-on-press-escape="false"
            :close-on-click-modal="false"
            title="在线客服"
            center
          >
            <div>
              <iframe
                src="https://ykf-webchat.7moor.com/wapchat.html?accessId=2b4e5e70-99c7-11eb-893b-a3f4e8b196a8&fromUrl=http://127.0.0.1&urlTitle=平台平台&language=ZHCN&otherParams="
                frameborder=""
                scrolling="yes"
                width="100%"
                height="700"
              ></iframe>
            </div>
          </el-dialog>

          <!-- <iframe
              src="https://ykf-webchat.7moor.com/wapchat.html?accessId=2b4e5e70-99c7-11eb-893b-a3f4e8b196a8&fromUrl=http://127.0.0.1&urlTitle=平台平台&language=ZHCN&otherParams="
              frameborder="" scrolling="yes" width="100%" height="377"></iframe> -->
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { getUUID, encode64, userSystemAgent } from '@/utils'
import Slider from '../common/components/slider'
import SliderMobile from '../common/components/slider-mobile'
import { isMobile } from '../../utils/validate'

export default {
  name: 'one-stop',
  components: { Slider, SliderMobile },
  data() {
    const checkMobile = (rule, value, callback) => {
      if (value === '') {
        return callback(new Error('请输入手机号码'))
      } else if (!isMobile(value)) {
        return callback(new Error('手机号码格式不正确'))
      } else {
        callback()
      }
    }
    const checkSmsCode = (rule, value, callback) => {
      if (value === '') {
        return callback(new Error('短信验证码不能为空'))
      } else {
        callback()
      }
    }
    return {
      wechatVisible: false,
      type: 'YJS',
      activeIndex: 0,
      radioSelected: '',
      user: {
        uuid: '',
        name: '',
        enterpriseName: '',
        mobile: '',
        smsCode: ''
      },
      lineNav: [
        {
          icon: 'icon-tel',
          label: '电话客服'
        },
        {
          icon: 'icon-zaixian',
          label: '在线客服'
        }
      ],
      dataRule: {
        name: [{ required: true, message: '姓名不能为空', trigger: 'blur' }],
        enterpriseName: [
          { required: true, message: '公司名称不能为空', trigger: 'blur' }
        ],
        mobile: [{ required: true, validator: checkMobile, trigger: 'blur' }],
        smsCode: [{ validator: checkSmsCode, trigger: 'blur' }]
      },
      loading: false,
      smsSuc: false,
      isMobile: false,
      smsTime: '',
      timer: new Date().getTime()
    }
  },
  mounted() {},
  created() {
    //判断是否移动端
    let ua = userSystemAgent()
    if (ua === 'isAndroid' || ua === 'isIOS') {
      this.isMobile = true
    }
  },
  computed: {
    userId: {
      get() {
        return this.$store.state.user.id
      }
    }
  },
  methods: {
    // 取消按钮 在线客服弹窗
    cancel() {
      ;(this.wechatVisible = false), (this.activeIndex = 0)
    },
    // 切换tab 方法 wechatVisible为1时 弹出弹框
    selectNav(index) {
      this.activeIndex = index
      if (this.activeIndex === 1) {
        this.wechatVisible = true
      }
    },
    // 电话客服滑块校验 成功后调获取验证码接口
    getMsgCode() {
      if (this.$refs['slider']) {
        // 未通过验证时，提示错误信息并返回
        if (!this.$refs['slider'].confirmSuccess) {
          this.$message.error('请拖动滑块验证')
          return
        }
      }
      let _this = this
      this.$refs['user'].validateField('mobile', mobileMsg => {
        if (mobileMsg === '') {
          _this
            .$http({
              url: _this.$http.adornUrl(_this.$api.SYS.VALID_CODE),
              method: 'post',
              data: _this.$http.adornData({
                mobile: _this.user.mobile,
                smsTerminal: 'ONE_STOP'
              })
            })
            .then(({ data }) => {
              if (data && data.code === 0) {
                this.$message.success('短信发送成功，请注意查收')
                _this.smsSuc = true
                _this.smsTime = '60s后可重新获取'
                _this.countdown(60)
              } else {
                this.$message.error(data.msg)
              }
            })
        }
      })
    },
    // 60s后可重新获取方法
    countdown(time) {
      if (time < 1) {
        this.smsSuc = false
        return
      }
      this.smsSuc = true
      this.smsTime = time + 's后可重新获取'
      setTimeout(this.countdown, 1000, --time)
    },
    // 提交按钮
    doSub() {
      this.$refs['user'].validate(valid => {
        if (valid && !this.loading) {
          this.loading = true
          this.$http({
            url: this.$http.adornUrl(this.$api.CONSULT.ONE_STOP),
            method: 'post',
            data: this.$http.adornData({
              mobile: this.user.mobile,
              enterpriseName: this.user.enterpriseName,
              name: this.user.name,
              operateUser: this.userId,
              smsCode: encode64(this.user.smsCode)
            })
          }).then(({ data }) => {
            this.loading = false
            if (data && data.code === 0) {
              this.$message.success('提交成功')
              this.$refs['user'].resetFields()
              this.timer = new Date().getTime()
            } else {
              this.$message.error(data.msg)
              this.timer = new Date().getTime()
            }
          })
        }
      })
    },
    // 顶部 tab 标签 切换时跳转的网址
    toStandardbzfw() {
      window.open('http://std.samr.gov.cn/')
    },
    toStandardzcfw() {
      window.open('http://gjzwfw.www.gov.cn/col/col644/index.html')
    },
    toStandardqyxycx() {
      window.open('http://www.gsxt.gov.cn/index.html')
    },
    toStandardzwqyml() {
      window.open('http://xwqy.gsxt.gov.cn/')
    },
    toStandardrzrkfw() {
      window.open('http://cx.cnca.cn/CertECloud/index/index/page')
    },
    toStandardsbjs() {
      window.open('https://tm.gongsibao.com/tm/tmdashboard')
    },
    toSCRM() {
      window.open('https://scrm.gongsibao.com/home.html')
    }
  },
  watch: {}
}
</script>
<style scoped>
.zxw-nav {
  /*background: linear-gradient(90deg, #35bce2, #409eff);*/
  background: white;
  line-height: 2.4rem;
  width: 100%;
  outline: none;
}

.zxw-nav .wrap {
  width: 1300px;
  margin: 0 auto;
}

.zxw-nav li {
  width: 7rem;
  color: #404040;
  text-align: center;
  cursor: pointer;
  font-size: 14px;
}

.zxw-nav li:hover,
.zxw-nav li.on {
  background: #69c2ff;
  font-size: 15px;
  color: white;
}

.pop-aside-wrap {
  height: 415px;
  /*padding-right: 50px;*/
  position: relative;
  overflow: hidden;
  /*line-height: 32px;*/
  transition: all 0.3s;
}

.pop-aside-wrap-expand {
  height: calc(100vh - 190px - 24px);
  /*overflow-y: auto;*/
  padding-bottom: 24px;
}

.el-popper[x-placement^='right'] {
  margin-left: 0;
  border-radius: 0;
  padding-left: 20px;
}

.menu-box .tit {
  color: white;
  width: 200px;
  line-height: 2.4rem;
  padding-left: 1.4rem;
  background: #409eff;
  border-radius: 10px 10px 0 0;
  /*margin-top: -2.4rem;*/
  cursor: pointer;
}

.menu-box {
  position: absolute;
  top: 50px;
  z-index: 1999;
  background: rgba(255, 255, 255, 0.95);
  box-shadow: 0 0 28px rgba(0, 0, 0, 0.08);
  border-radius: 10px;
}

.menu-box .menu {
  width: 200px;
  height: 408px;
  opacity: 1;
  transform: scale(1, 1);
  transform-origin: top left;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}

.menu-box .menu.hide {
  height: 0;
  width: 0;
  transform: scale(0, 0);
  opacity: 0;
}

.menu-box .menu > div {
  /*min-height: 452px;*/
}

.menu .tab .iconfont {
  font-size: 20px;
  width: 26px;
  margin-right: 6px;
  font-weight: normal;
}

.menu .tab-box {
  padding-top: 10px;
  padding-left: 0;
}

.menu .tab-box .tab {
  height: 48px;
  cursor: pointer;
  font-size: 16px;
  color: #6d6d6d;
  width: 100%;
  padding: 0 5px 0 13px;
  position: relative;
}

.menu .tab-box .tab:hover,
.menu .tab-box .tab.on {
  /*color: #409eff;*/
  background: #ffffff;
}

.menu .tab-box .tab:hover:before,
.menu .tab-box .tab.on:before {
  /*color: #409eff;*/
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  height: 42px;
  width: 100%;
  box-shadow: 0 0 28px rgba(0, 0, 0, 0.1);
  /*background: #ffffff;*/
  z-index: -1;
}

.menu-pop {
  height: 452px;
  position: absolute;
  left: 199px;
  top: 0;
  width: 200px;
  background: #fff;
  /*min-width: 200px !important;*/
  /*max-width: 750px !important;*/
  border-radius: 4px;
  border: 1px solid #ebeef5;
  padding: 12px;
  z-index: -1;
  color: #606266;
  line-height: 1.4;
  text-align: justify;
  font-size: 14px;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  word-break: break-all;
  transition: width 0.3s ease-in-out;
}

.menu-pop.large-type {
  width: 880px;
  transition: width 0.3s ease-in-out;
}

.menu-pop-expand {
  height: calc(100vh - 190px);
  padding: 12px 12px 42px 12px;
}

.box-shadow {
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
}

.pop-aside-wrap .pop-title {
  font-size: 15px;
  font-weight: bold;
  color: #333333;
  cursor: pointer;
}

.pop-aside-wrap .pop-title:hover {
  color: #409eff;
}

.pop-aside-wrap .second-list {
  cursor: pointer;
}

.pop-aside-wrap .second-wrap {
  /*flex-wrap: nowrap;*/
}

.pop-aside-wrap .second-list:hover {
  color: #409eff;
}

.menu .icon-jiantou-you {
  font-size: 12px !important;
}

/*服务贴边栏样式*/
.menu-box.fixed-left {
  position: fixed;
  left: 40px;
  top: 140px;
  border-radius: 0 10px 10px 10px;
}

.menu-box.fixed-left .el-icon-menu {
  margin-right: 6px;
  margin-bottom: 6px;
  margin-top: 10px;
}

.menu-box.fixed-left .tit {
  position: absolute;
  left: -40px;
  width: 40px;
  height: 200px;
  padding: 8px;
  flex-direction: column;
  line-height: 1.2rem;
  border-radius: 0;
}

.expand-btn {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 42px;
  line-height: 42px;
  background: #fff;
  opacity: 0.9;
  text-align: center;
  cursor: pointer;
  color: #409eff;
}

.expand-btn:hover {
  opacity: 0.8;
}

.suspension {
  position: fixed;
  width: 75px;
  height: 75px;
  border-radius: 50%;
  top: 140px;
  right: 100px;
  z-index: 2000;
  cursor: pointer;
  text-align: center;
  line-height: 75px;
  font-size: 16px;
  color: #409eff;
  background: rgba(0, 0, 0, 0.2);
}

/*需求弹框覆盖样式*/
.el-dialog /deep/ .el-dialog__footer {
  padding-top: 0;
}

.el-dialog /deep/ .el-dialog__header {
  padding-bottom: 0;
}

.el-form /deep/ .el-button {
  width: 150px;
  transform: translateX(-40px);
}

.icon-svg-menu {
  width: 24px;
  margin-right: 5px;
  text-align: center;
  font-size: 16px;
  color: inherit !important;
  transition: inline-block 0.3s, left 0.3s, width 0.3s, margin-left 0.3s,
    font-size 0.3s;
}

.zxw-nav >>> .el-scrollbar__wrap {
  overflow-x: hidden !important;
}

.banner2 {
  height: 44vh;
}

.banner2 .wrap {
  width: 80%;
  margin: 0 auto;
  height: 100%;
}

.container {
  width: 80%;
  background: white;
  margin: -30px auto 0 auto;
  border-radius: 14px;
  min-height: 40vh;

  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  padding: 15px;
}

.nav-item {
  margin: 0 20px;
}

.nav-item > .iconfont {
  font-size: 20px;
  margin-right: 10px;
}

.form-box {
  max-width: 600px;
  padding-top: 20px;
  margin: 0 auto;
}
</style>
